<template>
	<section
		v-if="showBreadcrumbs"
		:class="['breadcrumbs']"
	>
		<TnBreadcrumbs
			:breadcrumbs="breadcrumbs"
			:dark="dark"
			:type="$store.getters.isMobile ? 'scrollable' : 'default'"
			:removeSpacing="removeSpacing"
		/>
	</section>
</template>
<script>
import capitalize from "~/helpers/formatting/capitalize";

export default defineNuxtComponent({
	name: "Breadcrumbs",
	props: {
		loading: { type: Boolean, default: false },
		dark: { type: Boolean, default: false },
		path: { type: String, default: "" },
		metadata: { type: Object, default: () => ({}) },
		page: { type: Object, default: () => ({}) },
		layouts: { type: Array, default: () => [] },
		removeSpacing: { type: Boolean, default: false },
		forceShow: { type: Boolean, default: false },
		hasBackground: { type: Boolean, default: true },
	},

	computed: {
		showBreadcrumbs() {
			const cases = [
				this.breadcrumbs.length <= 1,
				this.metadata?.disableBreadcrumbs,
				!this.page?.template?.name,
				["twe-box", "article", "stripped", "login"].some((page) => this.page?.template?.name?.indexOf(page) > -1),
				this.layouts?.columns?.[0]?.[0]?.template?.name?.indexOf("hero") > -1,
			];
			return this.forceShow || !cases.some((e) => e);
		},
		segment() {
			return this.$store?.state?.siteSegment?.toLowerCase();
		},
		baseUrl() {
			return this.$store.state.domain;
		},
		breadcrumbs() {
			return (
				this.loading
					? [
							{ text: "", to: "" },
							{ text: "", to: "" },
						]
					: this.generateCrumbs
			).map((crumb) => {
				return {
					...crumb,
					text: decodeURIComponent(crumb.text),
				};
			});
		},
		generateCrumbs() {
			const breadcrumbs = [];
			parsePath(this.path, this.$route.path)
				.split("/")
				.filter((x) => x && x.indexOf("index.") === -1)
				.reduce(buildBreadcrumbPaths, [])
				.forEach((path) => {
					const crumbTitle = createCrumbTitle(path);
					breadcrumbs.push({
						to: `/${path.join("/").replace(/\/\//g, "/")}/`,
						text: capitalize(crumbTitle, true),
						title: crumbTitle,
					});
				});

			return breadcrumbs;
		},
		structuredData() {
			let breadcrumbs = JSON.parse(JSON.stringify(this.breadcrumbs));
			const listElements = [];

			breadcrumbs = breadcrumbs.filter((crumb) => crumb.title !== "");

			breadcrumbs.forEach((crumb, index) => {
				listElements.push({
					"@type": "ListItem",
					position: index + 1,
					item: `https://www.telenor.no${crumb.to}`,
					name: crumb.title,
				});
			});

			//Add current page
			const path = JSON.parse(JSON.stringify(this.$route.fullPath));
			const currentPath = path.split("/").filter((param) => param !== "" && param.indexOf("index") === -1);
			let currentTitle = currentPath.length && currentPath.pop();
			if (currentTitle && currentTitle !== "t-we") currentTitle = currentTitle.replace(/-/g, " ");

			if (currentTitle.length) {
				if (currentTitle.indexOf(".") > -1) currentTitle = currentTitle.split(".").shift();

				listElements.push({
					"@type": "ListItem",
					position: listElements.length + 1,
					name: currentTitle,
				});
			}

			return {
				"@context": "http://schema.org",
				"@type": "BreadcrumbList",
				itemListElement: listElements,
			};
		},
	},
	jsonld() {
		return this.structuredData;
	},
});
// Helpers
const siteSegment = ["privat", "bedrift"];

function lastElement(path) {
	return path[path.length - 1];
}

function isFrontpage(index) {
	return index === 0;
}

function doesNotIncludeSiteSegment(pathParameter) {
	return siteSegment.every((segment) => segment !== pathParameter);
}

function containsSiteSegment(pathParameter) {
	return siteSegment.some((segment) => segment === pathParameter);
}

function parsePath(path, routePath) {
	return path ? JSON.parse(JSON.stringify(path)) : JSON.parse(JSON.stringify(routePath));
}

function createCrumbTitle(path) {
	let title = lastElement(path).split(".")[0]; // Avoiding extensions like '.page' etc
	if (title !== "t-we") title = title.replace(/-(?!(?:t|we)\b)/gi, " ");
	if (containsSiteSegment(title)) title = "Forsiden";
	return title;
}

/**
 * Builds a breadcrumb path structure from path segments.
 *
 * This reducer function is used during a `.reduce()` call on an array of path parameters.
 * It accumulates breadcrumb paths by adding progressively longer slices of the original
 * path array. Additionally, under certain conditions (determined by `isFrontpage` and
 * `doesNotIncludeSiteSegment`), a predefined segment can be prepended to the paths.
 *
 * @param {Array<Array<string>>} paths - The accumulator for breadcrumb paths.
 * @param {string} currentParameter - The current path segment being processed.
 * @param {number} index - The index of the current path segment in the original array.
 * @param {Array<string>} original - The original array of path segments.
 * @returns {Array<Array<string>>} - The updated array of path arrays.
 */
function buildBreadcrumbPaths(paths, currentParameter, index, original) {
	paths.push(original.slice(0, index + 1));
	if (isFrontpage(index) && doesNotIncludeSiteSegment(currentParameter)) {
		paths.unshift(Array.of("privat")); // Insert sitesegment at the beginning
	}
	return paths;
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
	margin-left: -$spacing-m; /* Visually the breadcrumbs should align with the header, but the clickable element should be uniform. That's why this is necessary. */
}
</style>
